import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './Docs.css';

function Docs({ isAuthenticated }) {
  const [documents, setDocuments] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem('token');
      fetch('/api/docs', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setDocuments(data.documents);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error('Error fetching documents:', error);
        });
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDocuments(docs => docs.map(doc => (doc.id === isEditing ? { ...doc, content: data } : doc)));
  };

  const saveContent = () => {
    setIsSaving(true);
    const token = localStorage.getItem('token');
    const document = documents.find(doc => doc.id === isEditing);
    fetch(`/api/docs/${document.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ content: document.content }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setIsSaving(false);
        setIsEditing(null);
        alert(data.message);
      })
      .catch(error => {
        setIsSaving(false);
        console.error('Error saving content:', error);
      });
  };

  const createNewDocument = () => {
    const token = localStorage.getItem('token');
    fetch('/api/docs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ content: '' }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDocuments([...documents, { id: data.documentId, content: '' }]);
      })
      .catch(error => {
        console.error('Error creating document:', error);
      });
  };

  const handleEditClick = (documentId) => {
    if (isAuthenticated) {
      setIsEditing(documentId);
    } else {
      alert('You must be logged in to edit the document.');
    }
  };

  const deleteDocument = (documentId) => {
    const token = localStorage.getItem('token');
    fetch(`/api/docs/${documentId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDocuments(docs => docs.filter(doc => doc.id !== documentId));
        alert(data.message);
      })
      .catch(error => {
        console.error('Error deleting document:', error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="auth-message">
        <h2>Vous devez être authentifié pour voir cette page.</h2>
        <h2>
          Cliquez <Link to="/Login">ICI</Link> pour vous authentifier,
        </h2>
        <h2>Sinon merci d'envoyer un mail, à votre cher webmaster.</h2>
      </div>
    );
  }

  return (
    <div className="docs-container">
      <button onClick={createNewDocument} className="docs-new-button">
        New Document
      </button>
      {documents.map(doc => (
        <div key={doc.id} className="doc-item">
          <div className="docs-buttons">
            <button onClick={() => handleEditClick(doc.id)} className="docs-edit-button">
              {isEditing === doc.id ? 'Cancel' : 'Edit'}
            </button>
            <button onClick={() => deleteDocument(doc.id)} className="docs-delete-button">
              Delete
            </button>
          </div>
          {isEditing === doc.id && isAuthenticated ? (
            <div className="docs-editor-container">
              <CKEditor
                editor={ClassicEditor}
                data={doc.content}
                onChange={handleEditorChange}
              />
              <button onClick={saveContent} disabled={isSaving} className="docs-button">
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          ) : (
            <div className="docs-preview-container">
              <div dangerouslySetInnerHTML={{ __html: doc.content }} />
            </div>
          )}
        </div>
      ))}
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
    </div>
  );
}

export default Docs;

