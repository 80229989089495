import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Docs from './components/Docs';
import Tools from './components/Tools';
import Projects from './components/Projects';
import Login from './components/Login';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Tools" element={<Tools />} />
          <Route path="/Docs" element={<Docs />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Login" element={<Login />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

