import React, { useState } from 'react';
import './ContentSecurityPolicyValidator.css';

const ContentSecurityPolicyValidator = () => {
  const [url, setUrl] = useState('');
  const [cspReport, setCspReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkCSP = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://home.family13.fr/api/check_csp?url=${encodeURIComponent(url)}`);
      const result = await response.json();
      setCspReport(result);
    } catch (error) {
      console.error('Error checking CSP:', error);
      setCspReport({ error: 'Error checking CSP' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  return (
    <div className="csp-validator">
      <h2>CSP Validator</h2>
      <center>
        <input
          type="text"
          value={url}
          onChange={handleInputChange}
          placeholder="Enter URL..."
        />
        <button onClick={checkCSP} disabled={loading}>
          {loading ? 'Checking...' : 'Check'}
        </button>
        {cspReport && (
          <pre className="result">
            {JSON.stringify(cspReport, null, 2)}
          </pre>
        )}
      </center>
    </div>
  );
};

export default ContentSecurityPolicyValidator;

