import React, { useState } from 'react';
import './OpenRedirectScanner.css';

const OpenRedirectScanner = () => {
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');

  const checkOpenRedirect = async () => {
    try {
      const response = await fetch(`https://home.family13.fr/api/check_open_redirect?url=${encodeURIComponent(url)}`);
      const result = await response.json();
      if (result.vulnerable) {
        setMessage(`Warning: The URL "${url}" is vulnerable to open redirect.`);
      } else {
        setMessage(`The URL "${url}" has been tested and is safe from open redirect vulnerabilities.`);
      }
    } catch (error) {
      console.error('Error checking open redirect:', error);
      setMessage('An error occurred while checking the URL. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setUrl(value);
  };

  return (
    <div className="open-redirect-scanner">
      <h2>OpenRedir Vuln</h2>
      <center>
        <input
          type="text"
          value={url}
          onChange={handleInputChange}
          placeholder="Enter URL..."
        />
        <button onClick={checkOpenRedirect}>Check</button>
        {message && (
          <p className="result">
            {message}
          </p>
        )}
      </center>
    </div>
  );
};

export default OpenRedirectScanner;

