import React, { useState } from 'react';
import './Whois.css';

const Whois = () => {
  const [domain, setDomain] = useState('');
  const [whoisData, setWhoisData] = useState('');
  const [error, setError] = useState('');

  const handleWhoisCheck = async () => {
    setError('');
    setWhoisData('');
    try {
      const response = await fetch(`https://home.dev.as30781.net/api/whois?domain=${domain}`);
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        setWhoisData(data.whoisData);
      }
    } catch (err) {
      setError('Error fetching WHOIS data');
    }
  };

  return (
    <div className="whois-checker">
      <h2>Whois Checker</h2>
      <input 
        type="text" 
        value={domain} 
        onChange={(e) => setDomain(e.target.value)} 
        placeholder="Enter domain or IP" 
      />
      <button onClick={handleWhoisCheck}>Check</button>
      {error && <p className="error">{error}</p>}
      {whoisData && (
        <div className="result-container">
          <pre>{whoisData}</pre>
        </div>
      )}
    </div>
  );
};

export default Whois;

