import React, { useState } from 'react';
import './HashGenerator.css'; // Assurez-vous d'importer le bon fichier CSS ici
import CryptoJS from 'crypto-js';

const HashGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [hashMethod, setHashMethod] = useState('MD5');
  const [hashResult, setHashResult] = useState('');
  const [isEncryptMode, setIsEncryptMode] = useState(true);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleHashMethodChange = (e) => {
    setHashMethod(e.target.value);
  };

  const handleGenerateHash = () => {
    let result = '';
    switch (hashMethod) {
      case 'MD5':
        result = isEncryptMode ? CryptoJS.MD5(inputText).toString() : ''; // MD5 n'est pas réversible
        break;
      case 'SHA-1':
        result = isEncryptMode ? CryptoJS.SHA1(inputText).toString() : ''; // SHA-1 n'est pas réversible
        break;
      case 'SHA-256':
        result = isEncryptMode ? CryptoJS.SHA256(inputText).toString() : ''; // SHA-256 n'est pas réversible
        break;
      default:
        break;
    }
    setHashResult(result);
  };

  const toggleMode = () => {
    setIsEncryptMode(!isEncryptMode);
    setHashResult('');
    setInputText('');
  };

  return (
    <div className="hash-generator">
      <h2>Hash Generator</h2>
      <div className="input-group">
        <input
          type="text"
          id="inputText"
          value={inputText}
          onChange={handleInputChange}
          placeholder={isEncryptMode ? "Enter text to hash" : "Enter hash to decrypt"}
        />
        <select id="hashMethod" value={hashMethod} onChange={handleHashMethodChange} disabled={!isEncryptMode}>
          <option value="MD5">MD5</option>
          <option value="SHA-1">SHA-1</option>
          <option value="SHA-256">SHA-256</option>
        </select>
        <button onClick={handleGenerateHash}>{isEncryptMode ? 'Encrypt / HASH' : 'Decrypt'}</button>
        <button onClick={toggleMode}>{isEncryptMode ? 'Switch to Decrypt' : 'Switch to Encrypt'}</button>
      </div>
      {hashResult && (
        <div className="result">
          <p>{isEncryptMode ? `Hash Result (${hashMethod}):` : 'Decrypted Result:'}</p>
          <p>{hashResult}</p>
        </div>
      )}
    </div>
  );
};

export default HashGenerator;

