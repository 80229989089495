import React from 'react';
import './Header.css';
import './Tools.css';
import Footer from './Footer';
import Chmod from './Chmod';
import CrontabGenerator from './CrontabGenerator';
import IpMaskConverter from './IpMaskConverter';
import Calculette from './Calculette';
import SSLChecker from './SSLChecker';
import Whois from './Whois';
import Haveibeenpwned from './Haveibeenpwned';
import PingTool from './PingTool';
import TracerouteTool from './TracerouteTool';
import DNSLookup from './DNSLookup';
import IPGeolocalisation from './IPGeolocalisation';
import PortScanner from './PortScanner';
import HashGenerator from './HashGenerator';
import HttpHeaderViewer from './HttpHeaderViewer';
import JSONFormatterValidator from './JSONFormatterValidator';
import VulnerabilityScanner from './VulnerabilityScanner';
import SQLInjectionScanner from './SQLInjectionScanner';
import XSSScanner from './XSSScanner';
import ReverseDNSLookup from './ReverseDNSLookup';
import BruteForceProtectionChecker from './BruteForceProtectionChecker';
import IPBlacklistChecker from './IPBlacklistChecker';
import OpenRedirectScanner from './OpenRedirectScanner';
import ContentSecurityPolicyValidator from './ContentSecurityPolicyValidator';

const Tools = () => {
  return (
    <div className="main-grid">
      <br />
      <br />
      {/* Section Domain Name */}
      <h1 className="section-title">Domain Name</h1>
      <div className="main-card">
        <div className="card-grid">
          <div className="card">
            <SSLChecker />
          </div>
          <div className="card">
            <Whois />
          </div>
          <div className="card">
            <DNSLookup />
          </div>
          <div className="card">
            <ReverseDNSLookup />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      <br />
      {/* Section Network */}
      <h1 className="section-title">Network</h1>
      <div className="main-card">
        <div className="card-grid">
          <div className="card">
            <PingTool />
          </div>
          <div className="card">
            <TracerouteTool />
          </div>
          <div className="card">
            <IpMaskConverter />
          </div>
          <div className="card">
            <IPGeolocalisation />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      <br />
      {/* Section Security */}
      <h1 className="section-title">Security</h1>
      <div className="main-card">
        <div className="card-grid">
          <div className="card">
            <OpenRedirectScanner />
          </div>
          <div className="card">
            <VulnerabilityScanner />
          </div>
          <div className="card">
            <HttpHeaderViewer />
          </div>
          <div className="card">
            <Haveibeenpwned />
          </div>
          <div className="card">
            <ContentSecurityPolicyValidator />
          </div>
          <div className="card">
            <SQLInjectionScanner />
          </div>
          <div className="card">
            <XSSScanner />
          </div>
          <div className="card">
            <BruteForceProtectionChecker />
          </div>
          <div className="card">
            <IPBlacklistChecker />
          </div>
          <div className="card">
            <PortScanner />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      <br />
      {/* Section Calculator / Generator */}
      <h1 className="section-title">Calculator / Generator</h1>
      <div className="main-card">
        <div className="card-grid">
          <div className="card">
            <Calculette />
          </div>
          <div className="card">
            <CrontabGenerator />
          </div>
          <div className="card">
            <Chmod />
          </div>
          <div className="card">
            <HashGenerator />
            <JSONFormatterValidator />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />
      <br />

      {/* Section Other Tools */}
      <h1 className="section-title">Other Tools</h1>
      <div className="main-card">
        <div className="card-grid">
          <div className="card">
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

    </div>
  );
};

export default Tools;

