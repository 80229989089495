import React, { useState } from 'react';
import axios from 'axios';
import './IPGeolocalisation.css';

const IPGeolocalisation = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [locationInfo, setLocationInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchLocationInfo = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://ipapi.co/${ipAddress}/json/`);
      setLocationInfo(response.data);
    } catch (err) {
      setError('An error occurred: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ip-geolocalisation">
      <h2 className="title">IP Geoloc</h2>
      <input 
        type="text" 
        value={ipAddress} 
        onChange={(e) => setIpAddress(e.target.value)} 
        placeholder="Enter IP Address"
      />
      <button onClick={fetchLocationInfo} disabled={loading}>
        {loading ? 'Loading...' : 'Get Info'}
      </button>
      {error && <div className="error">{error}</div>}
      {locationInfo && (
        <div className="result">
          <h3>Location Information:</h3>
          <p><strong>Country:</strong> {locationInfo.country_name}</p>
          <p><strong>Region:</strong> {locationInfo.region}</p>
          <p><strong>City:</strong> {locationInfo.city}</p>
          <p><strong>Latitude:</strong> {locationInfo.latitude}</p>
          <p><strong>Longitude:</strong> {locationInfo.longitude}</p>
          <p><strong>ISP:</strong> {locationInfo.org}</p>
        </div>
      )}
    </div>
  );
};

export default IPGeolocalisation;

