// Calculette.js
import React, { useState } from 'react';
import './Calculette.css';

const Calculette = () => {
  const [input, setInput] = useState('');

  const handleButtonClick = (value) => {
    setInput((prev) => prev + value);
  };

  const handleClear = () => {
    setInput('');
  };

  const handleEqual = () => {
    try {
      setInput(eval(input).toString());
    } catch (error) {
      setInput('Error');
    }
  };

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  return (
    <div className="calculator-container">
      <h2>Calculator</h2>
      <input
        type="text"
        className="display"
        value={input}
        onChange={handleChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleEqual();
          }
        }}
      />
      <div className="buttons">
        <button onClick={() => handleButtonClick('1')}>1</button>
        <button onClick={() => handleButtonClick('2')}>2</button>
        <button onClick={() => handleButtonClick('3')}>3</button>
        <button onClick={() => handleButtonClick('+')}>+</button>
        <button onClick={() => handleButtonClick('4')}>4</button>
        <button onClick={() => handleButtonClick('5')}>5</button>
        <button onClick={() => handleButtonClick('6')}>6</button>
        <button onClick={() => handleButtonClick('-')}>-</button>
        <button onClick={() => handleButtonClick('7')}>7</button>
        <button onClick={() => handleButtonClick('8')}>8</button>
        <button onClick={() => handleButtonClick('9')}>9</button>
        <button onClick={() => handleButtonClick('*')}>*</button>
        <button onClick={handleClear}>C</button>
        <div className="spacer"></div>
        <button onClick={() => handleButtonClick('0')}>0</button>
        <div className="spacer"></div>
        <button onClick={handleEqual} className="double">=</button>
        <button onClick={() => handleButtonClick('/')}>/</button>
        <div className="spacer"></div>
      </div>
    </div>
  );
};

export default Calculette;

