import React, { useState } from 'react';
import axios from 'axios';
import './SSLChecker.css';

const SSLChecker = () => {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkSSL = async () => {
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await axios.get(`/api/sslcheck?host=${url}`);
      setResult(response.data);
      setLoading(false);
    } catch (err) {
      console.error(`Error fetching SSL data: ${err.message}`);
      setError(`Error fetching SSL data: ${err.message}`);
      setLoading(false);
    }
  };

  return (
    <div className="ssl-checker">
      <h2>SSL Checker</h2>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter website URL"
      />
      <button onClick={checkSSL} disabled={loading || !url}>
        {loading ? 'Loading...' : 'Check'}
      </button>
      {error && <div className="error">{error}</div>}
      {result && (
        <div className="result">
          <h3>Results for {url}</h3>
          <p><strong>IP Address:</strong> {result.ipAddress}</p>
          <p><strong>Server Name:</strong> {result.serverName}</p>
          <p><strong>Grade:</strong> {result.grade}</p>
          <p><strong>Expires On:</strong> {result.expiresOn}</p>
          <p><strong>Alternative Names:</strong> {result.altNames.join(', ')}</p>
        </div>
      )}
    </div>
  );
};

export default SSLChecker;

