import React, { useState } from 'react';
import './PortScanner.css';

const PortScanner = () => {
  const [target, setTarget] = useState('');
  const [startPort, setStartPort] = useState('');
  const [endPort, setEndPort] = useState('');
  const [ports, setPorts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const scanPorts = async () => {
    setLoading(true);
    setError('');
    setPorts([]);

    try {
      if (!target || !startPort || !endPort) {
        throw new Error('Please provide target and port range');
      }

      const response = await fetch(`/api/portscan?host=${target}&startPort=${startPort}&endPort=${endPort}`);
      const results = await response.json();
      setPorts(results);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="port-scanner-container">
      <h2>Port Scanner</h2>
      <div className="input-group">
        <input
          type="text"
          value={target}
          onChange={(e) => setTarget(e.target.value)}
          placeholder="Enter IP Address or Domain"
        />
        <div className="port-range">
          <input
            type="number"
            value={startPort}
            onChange={(e) => setStartPort(e.target.value)}
            placeholder="Start Port"
          />
          <input
            type="number"
            value={endPort}
            onChange={(e) => setEndPort(e.target.value)}
            placeholder="End Port"
          />
        </div>
        <button className="port-scanner-button" onClick={scanPorts} disabled={loading}>
          {loading ? 'Loading...' : 'Scan Ports'}
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {ports.length > 0 && (
        <div className="port-list">
          <h3>Open Ports:</h3>
          <ul>
            {ports.map((port, index) => (
              <li key={index}>
                Port: {port.port}, Status: {port.status}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PortScanner;

