// CrontabGenerator.js

import React, { useState } from 'react';
import './CrontabGenerator.css';

function CrontabGenerator() {
  const [minute, setMinute] = useState('*');
  const [hour, setHour] = useState('*');
  const [dayOfMonth, setDayOfMonth] = useState('*');
  const [month, setMonth] = useState('*');
  const [dayOfWeek, setDayOfWeek] = useState('*');

  const handleSelectChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const generateCrontab = () => {
    return `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;
  };

  return (
    <div className="crontab-generator">
      <h2>Cron Generator<br /></h2>
      <div>
        <label>Minute:</label>
        <select value={minute} onChange={handleSelectChange(setMinute)}>
          <option value="*">*</option>
          {Array.from({ length: 60 }).map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Hour:</label>
        <select value={hour} onChange={handleSelectChange(setHour)}>
          <option value="*">*</option>
          {Array.from({ length: 24 }).map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Day of Month:</label>
        <select value={dayOfMonth} onChange={handleSelectChange(setDayOfMonth)}>
          <option value="*">*</option>
          {Array.from({ length: 31 }).map((_, i) => (
            <option key={i + 1} value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Month:</label>
        <select value={month} onChange={handleSelectChange(setMonth)}>
          <option value="*">*</option>
          {Array.from({ length: 12 }).map((_, i) => (
            <option key={i + 1} value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Day of Week:</label>
        <select value={dayOfWeek} onChange={handleSelectChange(setDayOfWeek)}>
          <option value="*">*</option>
          {Array.from({ length: 7 }).map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
      </div>
      <div>
        <h3>Generated Crontab:</h3>
        <code>{generateCrontab()}</code>
      </div>
    </div>
  );
}

export default CrontabGenerator;

