import React, { useState } from 'react';
import './BruteForceProtectionChecker.css';

const BruteForceProtectionChecker = () => {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const checkProtection = async () => {
    setLoading(true);
    setResult(null);
    try {
      const response = await fetch(`/api/bruteforce?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      setResult(data);
    } catch (error) {
      setResult({ error: 'Error checking brute force protection' });
    }
    setLoading(false);
  };

  return (
    <div className="bruteforce-protection-checker">
      <h2>BruteForce</h2>
      <center>
        <input
          type="text"
          value={url}
          onChange={handleUrlChange}
          placeholder="Enter URL to check"
        />
        <button onClick={checkProtection} disabled={loading}>
          {loading ? 'Checking...' : 'Check'}
        </button>
        {result && (
          <div className="result">
            {result.error ? (
              <p className="error">{result.error}</p>
            ) : (
              <div>
                <p>{result.protection ? 'Protected' : 'Not Protected'}</p>
                {!result.protection && (
                  <div className="recommendation">
                    <h3>Recommendation:</h3>
                    <p>
                      It is recommended to implement security measures to protect against brute force attacks. 
                      Consider using CAPTCHA, account lockout mechanisms, and monitoring failed login attempts.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </center>
    </div>
  );
};

export default BruteForceProtectionChecker;

