import React, { useEffect, useState } from 'react';
import './Home.css'; // Utilisez les styles de Home.css
import Footer from './Footer';
import Card from './Card'; // Assurez-vous que vous avez un composant Card

function Home() {
  // State pour les iframes visibles
  const [visibleIframes, setVisibleIframes] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleIframes((prev) => [...prev, entry.target.dataset.url]);
          observer.unobserve(entry.target);
        }
      });
    });

    document.querySelectorAll('.iframe-placeholder').forEach((el) => {
      observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const shareCards = [
    { title: "Share Files", url: "https://share.family13.fr/upload", buttonText: "Share" },
    { title: "Share Secret", url: "https://secret.family13.fr", buttonText: "Secret" },
    { title: "Design Your Own Infra", url: "https://drawio.family13.fr/", buttonText: "Start Drawing" },
    { title: "Create Your Notes", url: "https://note.family13.fr/", buttonText: "Write Notes" }
  ];

  const multimediaCards = [
    { title: "Listen Music - KOEL Server", url: "https://music.family13.fr/", buttonText: "Play" },
    { title: "Movies & Series - Plex Server", url: "https://plex.family13.fr/", buttonText: "Watch" },
    { title: "Documentations - Wikijs", url: "https://wiki.family13.fr/", buttonText: "KnowledgeBases" },
    { title: "Create Your Video Conference", url: "https://visio.family13.fr/", buttonText: "Join Now" }
  ];

  const otherCards = [
    { title: "Nextcloud and More", url: "https://cloud.family13.fr/", buttonText: "Cloud" },
    { title: "Create Your Survey", url: "https://qcm.family13.fr/", buttonText: "Survey" },
    { title: "Download Music", url: "https://ydl.family13.fr/", buttonText: "Start Convert" },
  ];

  const infrastructureCards = [
    { title: "Monitoring HTTPS", url: "https://kuma.family13.fr/", buttonText: "Uptime" },
    { title: "Infrastructure As Code", url: "https://gitlab.family13.fr/", buttonText: "GITLAB" }
  ];

  const creationsCards = [
    { title: "Home Family", url: "https://home.family13.fr", buttonText: "HOME" },
    { title: "Massilia Services VTC", url: "https://massiliaservicesvtc.fr", buttonText: "VTC" },
    { title: "Youtube Downloader", url: "https://ydl.family13.fr", buttonText: "Dev en cours" },
    { title: "Tracking Colis", url: "https://colis.family13.fr", buttonText: "Dev en cours" },
    { title: "Pronos Sportif", url: "https://pronos.family13.fr", buttonText: "Dev en cours" },
    { title: "MTR Piscines", url: "https://mtrpiscines.fr", buttonText: "v2 en cours" },
  ];

  return (
    <div className="home-container main-grid">
      <br />
      <br />
      <h1><code>ITS NOT JUST A WEBSITE</code></h1>
      <img src="/test.webp" width="1200" height="500" className="rounded-image" alt="" />
      <br />
      <br />
      <br />

      {/* Section Share Files Securely */}
      <h1 className="section-title">Share Files Securely</h1>
      <div className="main-card">
        <div className="card-grid">
          {shareCards.map(card => (
            <div className="iframe-placeholder" key={card.url} data-url={card.url}>
              <Card title={card.title} url={visibleIframes.includes(card.url) ? card.url : null} buttonText={card.buttonText} />
            </div>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      {/* Section Multimedia */}
      <h1 className="section-title">Multimedia</h1>
      <div className="main-card">
        <div className="card-grid">
          {multimediaCards.map(card => (
            <div className="iframe-placeholder" key={card.url} data-url={card.url}>
              <Card title={card.title} url={visibleIframes.includes(card.url) ? card.url : null} buttonText={card.buttonText} />
            </div>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      {/* Section Other */}
      <h1 className="section-title">Other</h1>
      <div className="main-card">
        <div className="card-grid">
          {otherCards.map(card => (
            <div className="iframe-placeholder" key={card.url} data-url={card.url}>
              <Card title={card.title} url={visibleIframes.includes(card.url) ? card.url : null} buttonText={card.buttonText} />
            </div>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <hr className="separator" />

      {/* Section Infrastructure */}
      <h1 className="section-title">Infrastructure</h1>
      <div className="main-card">
        <div className="card-grid">
          {infrastructureCards.map(card => (
            <div className="iframe-placeholder" key={card.url} data-url={card.url}>
              <Card title={card.title} url={visibleIframes.includes(card.url) ? card.url : null} buttonText={card.buttonText} />
            </div>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
      <hr className="separator" />

      {/* Section Mes créations SITES WEB en cours */}
      <h1 className="section-title">Mes créations SITES WEB en cours</h1>
      <div className="main-card">
        <div className="card-grid">
          {creationsCards.map(card => (
            <div className="iframe-placeholder" key={card.url} data-url={card.url}>
              <Card title={card.title} url={visibleIframes.includes(card.url) ? card.url : null} buttonText={card.buttonText} />
            </div>
          ))}
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
}

export default Home;

