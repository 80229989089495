import React, { useState } from 'react';
import './DNSLookup.css';

const DNSLookup = () => {
  const [domain, setDomain] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const checkDNS = async () => {
    setLoading(true);
    setError('');
    setResult(null);

    try {
      const response = await fetch(`https://home.family13.fr/api/dnslookup?domain=${domain}`);
      const text = await response.text(); // Obtenez la réponse sous forme de texte

      console.log('Response:', text); // Log la réponse pour voir ce que vous obtenez

      if (response.ok) {
        const data = JSON.parse(text); // Parse le texte en JSON si la réponse est correcte
        setResult(data.dnsResult);
      } else {
        const errorData = JSON.parse(text); // Parse le texte en JSON si la réponse n'est pas correcte
        setError(errorData.error || 'An error occurred');
      }
    } catch (err) {
      setError('An error occurred: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dns-lookup">
      <h2 className="dns-lookup-title">DNS Lookup</h2>
      <center>
        <input
          type="text"
          placeholder="Enter domain"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
      </center>
      <center>
        <button onClick={checkDNS} disabled={loading}>
          {loading ? 'Loading...' : 'Check'}
        </button>
      </center>
      {error && <p className="error">{error}</p>}
      {result && (
        <div className="result-container">
          <ul>
            {result.split('\n').map((line, index) => (
              <li key={index}>{line}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DNSLookup;

