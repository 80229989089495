import React, { useState } from 'react';
import './PingTool.css';

const PingTool = () => {
  const [host, setHost] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const checkPing = async () => {
    setLoading(true);
    setError('');
    setResult(null);

    // Nettoyer l'entrée de l'utilisateur
    const cleanedHost = host.trim().replace(/\.$/, '');

    try {
      const response = await fetch(`https://home.dev.as30781.net/api/ping?host=${cleanedHost}`);
      const data = await response.json();

      if (response.ok) {
        setResult(data.pingResult);
      } else {
        setError(data.error || 'An error occurred');
      }
    } catch (err) {
      setError('An error occurred: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ping-tool">
      <h2 className="ping-title">Ping Tool</h2>
      <input
        type="text"
        value={host}
        onChange={(e) => setHost(e.target.value)}
        placeholder="Enter IP or Domain"
      />
      <button onClick={checkPing} disabled={loading}>
        {loading ? 'Loading...' : 'Ping'}
      </button>
      {error && <p className="error">{error}</p>}
      {result && (
        <div className="result-container">
          <pre>{result}</pre>
        </div>
      )}
    </div>
  );
};

export default PingTool;

