import React from 'react';
import './Card.css';

function Card({ title, url, buttonText }) {
  return (
    <div className="card">
      <h3>{title}</h3>
      <iframe src={url} title={title}></iframe>
      <a href={url} target="_blank" rel="noopener noreferrer">{buttonText}</a>
    </div>
  );
}

export default Card;

