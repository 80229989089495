import React, { useState } from 'react';
import './IpMaskConverter.css';
import { calculateSubnetMask } from 'ip-subnet-calculator';

function IpMaskConverter() {
  const [input, setInput] = useState('');
  const [subnetInfo, setSubnetInfo] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log("IP/CIDR:", value);
    setInput(value);
  };

  const calculateSubnet = () => {
    if (input) {
      const [ip, mask] = input.split('/');
      if (ip && mask) {
        const result = calculateSubnetMask(ip, mask);
        console.log("Subnet result:", result);
        setSubnetInfo(result);
      }
    }
  };

  return (
    <div className="ip-mask-converter">
      <h2>IPMask Calc</h2>
      <div className="input-group">
        <input 
          type="text" 
          value={input} 
          onChange={handleInputChange} 
          placeholder="IP/CIDR (e.g., 192.168.1.1/24)"
        />
      </div>
      <button onClick={calculateSubnet}>Calculate</button>
      {subnetInfo && (
        <div className="result">
          <h3>Subnet Information:</h3>
          <p>Network Address: {subnetInfo.ipLowStr}</p>
          <p>Broadcast Address: {subnetInfo.ipHighStr}</p>
          <p>First Host: {subnetInfo.ipLowStr}</p>
          <p>Last Host: {subnetInfo.ipHighStr}</p>
          <p>Total Hosts: {subnetInfo.prefixSize}</p>
        </div>
      )}
    </div>
  );
}

export default IpMaskConverter;

