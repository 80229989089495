import React, { useState } from 'react';
import './IPBlacklistChecker.css';

const IPBlacklistChecker = () => {
  const [ip, setIP] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkBlacklist = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/ip_blacklist_check?ip=${encodeURIComponent(ip)}`);
      const result = await response.json();
      setIsBlacklisted(result.blacklisted);
    } catch (error) {
      console.error('Error checking blacklist:', error);
      setIsBlacklisted(null);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setIP(value);
  };

  return (
    <div className="ip-blacklist-checker">
      <h2>IP Blacklist</h2>
      <center>
        <input
          type="text"
          value={ip}
          onChange={handleInputChange}
          placeholder="Enter IP address..."
        />
        <button onClick={checkBlacklist} disabled={loading}>
          {loading ? 'Checking...' : 'Check'}
        </button>
        {isBlacklisted !== null && (
          <p className={`result ${isBlacklisted ? 'blacklisted' : 'not-blacklisted'}`}>
            {isBlacklisted ? 'IP is blacklisted' : 'IP is not blacklisted'}
          </p>
        )}
      </center>
    </div>
  );
};

export default IPBlacklistChecker;

