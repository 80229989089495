import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import './Header.css'; // Importe le fichier CSS pour styler le menu
import ScrollingText from './ScrollingText';

const rainColors = ['#1A6DFF', '#6DC7FF', '#E6ABFF', '#C822FF'];

const rainDrop = keyframes`
  0% {
    top: -10%;
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
`;

const RainDrop = styled.div`
  position: absolute;
  top: -10%;
  font-size: 1.5em;
  color: ${(props) => props.color};
  animation: ${rainDrop} 2s linear infinite;
`;

const generateRainDrops = () => {
  let drops = [];
  for (let i = 0; i < 15; i++) { // Réduit le nombre de gouttes à 50
    const color = rainColors[Math.floor(Math.random() * rainColors.length)];
    const left = Math.random() * 100;
    const delay = Math.random() * 2;
    const content = Math.random() > 0.5 ? '1' : '0'; // Alternance entre 1 et 0
    drops.push(<RainDrop key={i} color={color} style={{ left: `${left}%`, animationDelay: `${delay}s` }}>{content}</RainDrop>);
  }
  return drops;
};

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // This useEffect can be used to trigger any side effects if needed
  }, []);

  return (
    <header className="header"> {/* Applique la classe header */}
      {generateRainDrops()}
      <nav>
        <div className="menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={isMenuOpen ? "menu menu-open" : "menu"}>
          <li className="home-link">
            <Link to="/"><img src="/favicon.ico" alt="Home" className="logo" /></Link> {/* Assure-toi que la classe 'logo' est ajoutée ici */}
          </li>
          <li className="t5"><ScrollingText /></li> {/* Ajoute la classe 'middle-text' ici */}
          <div className="menu-right"> {/* Ajoute une div pour les éléments à droite */}
            <li><Link to="/Tools" className="t1">Tools</Link></li>
            <li><Link to="/Docs" className="t2">Docs</Link></li>
            <li><Link to="/Projects" className="t3">Projects</Link></li>
            <li><Link to="/Login" className="t4">Login</Link></li>
          </div>
        </ul>
      </nav>
    </header>
  );
}

export default Header;

