// ReverseDNSLookup.js

import React, { useState } from 'react';
import './ReverseDNSLookup.css';

const ReverseDNSLookup = () => {
  const [ip, setIp] = useState('');
  const [result, setResult] = useState('');

  const handleChange = (e) => {
    setIp(e.target.value);
  };

  const handleLookup = async () => {
    try {
      const response = await fetch(`https://api.hackertarget.com/reversedns/?q=${ip}`);
      const data = await response.text();
      setResult(data);
    } catch (error) {
      setResult('Error performing lookup');
    }
  };

  return (
    <div className="reverse-dns-lookup">
      <h2>Rev DNSLookup</h2>
      <input 
        type="text" 
        value={ip} 
        onChange={handleChange} 
        placeholder="Enter IP address" 
      />
      <button onClick={handleLookup}>Lookup</button>
      {result && <pre className="result">{result}</pre>}
    </div>
  );
};

export default ReverseDNSLookup;

