import React, { useState } from 'react';
import './HttpHeaderViewer.css'; // Assurez-vous d'importer le fichier CSS approprié ici

const HttpHeaderViewer = () => {
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState(null);
  const [error, setError] = useState('');

  const fetchHeaders = async () => {
    try {
      const response = await fetch(url, {
        method: 'HEAD' // Utilisation de HEAD pour récupérer uniquement les en-têtes sans le corps de la réponse
      });

      const headers = response.headers;
      setHeaders(headers);
      setError('');
    } catch (error) {
      setError('Erreur lors de la récupération des en-têtes. Assurez-vous que l\'URL est correcte et que vous avez accès à cette ressource.');
      setHeaders(null);
    }
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchHeaders();
  };

  return (
    <div className="http-header-viewer">
      <h2>HTTP Header</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="urlInput"
          value={url}
          onChange={handleUrlChange}
          placeholder="https://example.com"
        />
        <button type="submit">Check</button>
      </form>
      {error && <p className="error">{error}</p>}
      {headers && (
        <div className="headers">
          <h3>En-têtes HTTP pour {url}</h3>
          <pre>
            {Array.from(headers.entries()).map(([key, value]) => (
              <div key={key}>
                <strong>{key}:</strong> {value}
              </div>
            ))}
          </pre>
        </div>
      )}
    </div>
  );
};

export default HttpHeaderViewer;

