// JSONFormatterValidator.js

import React, { useState } from 'react';
import './JSONFormatterValidator.css';

const JSONFormatterValidator = () => {
  const [inputJSON, setInputJSON] = useState('');
  const [formattedJSON, setFormattedJSON] = useState('');
  const [isValidJSON, setIsValidJSON] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleJSONInputChange = (event) => {
    const { value } = event.target;
    setInputJSON(value);
  };

  const formatJSON = () => {
    try {
      const parsedJSON = JSON.parse(inputJSON);
      const formatted = JSON.stringify(parsedJSON, null, 2);
      setFormattedJSON(formatted);
      setIsValidJSON(true);
      setErrorMessage('');
    } catch (error) {
      setIsValidJSON(false);
      setFormattedJSON('Invalid JSON');
      if (error instanceof SyntaxError) {
        const message = error.message;
        if (message.includes('Unexpected token')) {
          const splitMessage = message.split('Unexpected token')[1];
          const location = splitMessage.match(/\d+/g);
          if (location && location.length >= 2) {
            const [lineNumber, columnNumber] = location;
            const errorMessage = `Unexpected token at line ${lineNumber}, column ${columnNumber}`;
            setErrorMessage(errorMessage);
          } else {
            setErrorMessage('Invalid JSON format');
          }
        } else {
          setErrorMessage('Invalid JSON format');
        }
      } else {
        setErrorMessage('Unknown error occurred. Please check your JSON.');
      }
    }
  };

  return (
    <div className="json-formatter-validator">
      <h2>JSON Formatter & Validator</h2>
      <textarea
        className={isValidJSON ? 'input-json valid' : 'input-json invalid'}
        value={inputJSON}
        onChange={handleJSONInputChange}
        placeholder="Enter JSON here..."
      />
      <button onClick={formatJSON}>Format / Validate</button>
      <div className="output-json">
       <center><h3>Formatted JSON:</h3></center>
        {isValidJSON ? (
          <pre>{formattedJSON}</pre>
        ) : (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JSONFormatterValidator;

