 
import React from 'react';
import Typed from 'typed.js';

class ScrollingText extends React.Component {
  componentDidMount() {
    const options = {
      strings: ['...THE ROAD SO FAR...'],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true
    };

    this.typed = new Typed(this.textContainer, options);
  }

  componentWillUnmount() {
    this.typed.destroy();
  }

  render() {
    return <span ref={(elem) => { this.textContainer = elem; }} />;
  }
}

export default ScrollingText;

