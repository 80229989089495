import React, { useState } from 'react';
import './Haveibeenpwned.css';

const Haveibeenpwned = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  const checkEmail = async () => {
    setError('');
    setResult(null);

    try {
      const response = await fetch(`https://home.family13.fr/api/leakcheck?email=${email}`);
      const data = await response.json();

      if (response.ok) {
        setResult(data);
      } else {
        setError(data.error || 'An error occurred');
      }
    } catch (err) {
      setError('An error occurred: ' + err.message);
    }
  };

  return (
    <div className="haveibeenpwned">
      <h2>I Been Pwned?</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={checkEmail}>Check</button>
      {error && <p className="error">{error}</p>}
      {result && (
        <div className="result-container">
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Haveibeenpwned;

