import React, { useState } from 'react';
import './SQLInjectionScanner.css';

const SQLInjectionScanner = () => {
  const [url, setUrl] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const scanForSQLInjection = async () => {
    setLoading(true);
    setResult(null);
    try {
      const response = await fetch(`/api/sqlinjection?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      setResult(data);
    } catch (error) {
      setResult({ error: 'Failed to scan the URL' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sql-injection-scanner">
      <h2>SQL Inject Scan</h2>
      <input
        type="text"
        value={url}
        onChange={handleChange}
        placeholder="Enter URL to scan"
      />
      <button onClick={scanForSQLInjection} disabled={loading}>
        {loading ? 'Scanning...' : 'Scan'}
      </button>
      {loading && <p>Loading...</p>}
      {result && (
        <div className="result">
          {result.error ? (
            <p>{result.error}</p>
          ) : (
            <p>{result.message}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SQLInjectionScanner;

