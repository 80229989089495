import React, { useState } from 'react';
import './Chmod.css';

const Chmod = () => {
  const [permissions, setPermissions] = useState({
    owner: { read: false, write: false, execute: false, letters: '' },
    group: { read: false, write: false, execute: false, letters: '' },
    others: { read: false, write: false, execute: false, letters: '' },
    special: { suid: false, sgid: false, sticky: false },
  });

  const handleChange = (type, permission) => {
    setPermissions((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [permission]: !prevState[type][permission],
      },
    }));
  };

  const handleLetterChange = (type, value) => {
    const letters = value.toLowerCase().replace(/[^rwx]/g, '');
    setPermissions((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        letters,
      },
    }));
  };

  const calculatePermissionValue = (type) => {
    const { read, write, execute } = permissions[type];
    return (read ? 4 : 0) + (write ? 2 : 0) + (execute ? 1 : 0);
  };

  const calculateSpecialBits = () => {
    const { suid, sgid, sticky } = permissions.special;
    return (suid ? 4 : 0) + (sgid ? 2 : 0) + (sticky ? 1 : 0);
  };

  const calculateChmodValue = () => {
    const specialBits = calculateSpecialBits();
    const owner = calculatePermissionValue('owner');
    const group = calculatePermissionValue('group');
    const others = calculatePermissionValue('others');
    return `${specialBits}${owner}${group}${others}`;
  };

  const calculateInverseChmodValue = (letters) => {
    let value = 0;
    if (letters.includes('r')) value += 4;
    if (letters.includes('w')) value += 2;
    if (letters.includes('x')) value += 1;
    return value.toString();
  };

  return (
    <div className="chmod-container">
      <h2>Chmod Calc</h2>
      <div className="permissions">
        {['owner', 'group', 'others'].map((type) => (
          <div key={type} className="permission-group">
            <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
            {['read', 'write', 'execute'].map((perm) => (
              <label key={perm}>
                <input
                  type="checkbox"
                  checked={permissions[type][perm]}
                  onChange={() => handleChange(type, perm)}
                />
                {perm.charAt(0).toUpperCase() + perm.slice(1)}
              </label>
            ))}
          </div>
        ))}
        <div className="special-bits">
          <h3>Special Bits</h3>
          {['suid', 'sgid', 'sticky'].map((bit) => (
            <label key={bit}>
              <input
                type="checkbox"
                checked={permissions.special[bit]}
                onChange={() => handleChange('special', bit)}
              />
              {bit.toUpperCase()}
            </label>
          ))}
        </div>
      </div>
      <div className="result">
        <h3>Chmod Value: {calculateChmodValue()}</h3>
      </div>
    </div>
  );
};

export default Chmod;

